<template>
  <div class="contract view" id="departure-reports">
    <a-collapse
      class="travel-filters expandable mt-0 mb-30"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-0" :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Período</label>
              <a-range-picker
                v-model="filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="10">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                optionFilterProp="txt"
                mode="multiple"
                v-model="filters.users.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.users.list"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6" style="display: none">
            <div class="travel-input">
              <label class="filled">Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o status do contrato"
                mode="multiple"
                v-model="filters.status.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <!-- <pre> {{this.chartOptions.series[0].data}}</pre>  -->

    <div v-if="generateChart">
      <div class="a-right">
        <a-button
          class="ml-10"
          @click="generatePDF()"
          size="default"
          type="danger"
          :loading="generatePDFLoading"
          ghost
        >
          <a-icon type="file-pdf" /> PDF
        </a-button>
      </div>
      <div id="print-area">
        <Chart
          v-if="countProductsx().chartArr.length > 0"
          :chartOptions="chartOptions"
        />

        <div class="extra">
          <a-table
            class="chart-table mt-20"
            :columns="[
              {
                title: 'Nome',
                scopedSlots: { customRender: 'name' },
              },
              {
                title: '%',
                scopedSlots: { customRender: 'percentage' },
                width: 120,
              },
              {
                title: 'Vendas',
                scopedSlots: { customRender: 'value' },
                sorter: true,
                defaultSortOrder: 'descend',
                width: 120,
                sorter: (a, b) => a.value - b.value,
              },
              {
                title: 'Qtd. Quartos',
                scopedSlots: { customRender: 'totalRooms' },
                sorter: true,
                width: 120,
                sorter: (a, b) => a.totalRooms - b.totalRooms,
              },
              {
                title: 'Ticket Médio',
                scopedSlots: { customRender: 'averageTicket' },
                sorter: true,
                width: 120,
                sorter: (a, b) => a.averageTicket - b.averageTicket,
              },
            ]"
            :data-source="countProductsx().chartArr"
            :loading="loading"
            :pagination="false"
          >
            <template slot="name" slot-scope="record">
              <div style="width: 600px">{{ record.name }}</div>
            </template>
            <template slot="percentage" slot-scope="record">
              {{
                formatPercentage(
                  (100 * record.value) / countProductsx().totalQuantity
                )
              }}
            </template>
            <template slot="averageTicket" slot-scope="record">
              {{ formatPricePtBr(record.averageTicket) }}
            </template>
            <template slot="totalRooms" slot-scope="record">
              {{ record.totalRooms }}
            </template>
            <template slot="value" slot-scope="record">
              {{ record.value }}
            </template>
            <template slot="footer">
              <a-row style="padding: 6px 0" type="flex">
                <a-col flex="auto"> <b>TOTAL </b></a-col>
                <a-col flex="120px">
                  <b>100%</b>
                </a-col>

                <a-col flex="118px">
                  <b>{{ countProductsx().totalQuantity }}</b>
                </a-col>

                <a-col flex="123px">
                  <b>{{ countProductsx().totalAccomodations }}</b>
                </a-col>

                <a-col flex="120px">
                  <b>{{ formatPricePtBr(countProductsx().totalValue) }}</b>
                </a-col>
              </a-row>
            </template>
          </a-table>

          <a-alert
            class="mt-30"
            type="info"
            message="Observação: Alguns valores do Ticket Médio podem estar zerados pois são de pacotes de terceiros, portanto não foram contabilizados."
            banner
            style="font-size: 12px"
          />
        </div>
      </div>
    </div>
    <div v-if="!generateChart">
      <a-skeleton active />
      <a-skeleton active />
      <a-skeleton active />
    </div>

    <!-- <pre> <hr />{{ countProductsx() }}   </pre> -->
  </div>
</template>

<script>
import _ from "lodash";
import html2pdf from "html2pdf.js";
import { parse } from "vue-currency-input";
import { format } from "date-fns";

import Chart from "@/components/general/Chart.vue";
import formatThings from "@/mixins/general/formatThings.js";
import customerMixins from "@/mixins/customers/customerMixins.js";
import useGeneratePDF from "@/composables/generatePDF.js";


export default {
  mixins: [formatThings, customerMixins],
  components: {
    Chart,
  },
  setup() {
    const { generatePDF } = useGeneratePDF();
    return { generatePDF };
  },
  data() {
    return {
      generateChart: false,
      chartInfo: { title: "Relatório de Produtos", subtitle: "" },
      chartOptions: {
        title: {
          text: "Relatório de Produtos",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{c} - {b} ({d}%)",
        },
        // legend: {
        //     orient: "vertical",
        //     left: "left",
        //     data: [
        //         "GPI",
        //         "Summer",
        //         "Ad Networks",
        //         "Video Ads",
        //         "Search Engines",
        //     ],
        // },
        series: [
          {
            name: "Nome:",
            type: "pie",
            radius: "55%",
            // center: ["50%", "60%"],
            data: [],
            emphasis: {
              itemStyle: {
                //shadowBlur: 10,
                shadowOffsetX: 0,
                //shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      filters: {
        loadingResults: false,
        id: "",
        paymentMethods: {
          list: [
            {
              label: "Cartão de Crédito",
              value: "credit-card",
            },
            {
              label: "Boleto",
              value: "billing-ticket",
            },
            {
              label: "Transferência Bancária",
              value: "bank-transfer",
            },
            {
              label: "Carta de Crédito",
              value: "payment-card",
            },
            {
              label: "Link de Pagamento",
              value: "payment-link",
            },
          ],
          selected: [],
        },
        companies: {
          list: [
            {
              label: "Viajar Resorts",
              value: "Viajar Resorts",
            },
            {
              label: "Voe Simples",
              value: "Voe Simples",
            },
            {
              label: "Credimilhas",
              value: "Credimilhas",
            },
          ],
          selected: [],
        },
        personTypes: {
          list: [
            {
              label: "Pessoa Física",
              value: "Pessoa Física",
            },
            {
              label: "Pessoa Jurídica",
              value: "Pessoa Jurídica",
            },
          ],
          selected: [],
        },
        users: {
          list: [],
          selected: [],
        },
        productsCategory: {
          list: [
            {
              label: "Hotéis/Resorts",
              value: "hotel",
            },
            {
              label: "Aéreo",
              value: "flight",
            },
            {
              label: "Serviços",
              value: "service",
            },
          ],
          selected: [],
        },
        products: {
          list: [],
          selected: [],
        },
        clients: {
          list: [],
          selected: [],
        },
        status: {
          list: [
            {
              label: "Concluído",
              value: "concluded",
            },
            {
              label: "Em edição",
              value: "edit",
            },
            {
              label: "Cancelado",
              value: "canceled",
            },
          ],
          selected: ["concluded"],
        },
        embark: {
          selected: [],
        },
        period: {
          selected: [],
        },
        searchTerm: "",
        price: {
          min: "",
          max: "",
        },
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 500,
        total: 0,
        totalPages: 0,
      },
      contractList: [],
      loading: false,
    };
  },
  beforeMount() {
    document.title = "Relatório de Produtos";

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("period")) {
      this.filters.period.selected = urlParams.get("period").split("|");
    }

    this.getContracts();
    this.getUsers();
  },
  methods: {
    countProductsx() {
      let hotelArr = [];
      let newArr = [];
      let chartArr = [];
      let totalQuantity = 0;
      let totalAccomodations = 0;
      let totalValue = 0;

      if (this.contractList.length > 0) {
        this.contractList.forEach((contract) => {
          // HOTEL
          if (JSON.parse(contract.contracted_services).includes("hotel")) {
            JSON.parse(contract.meta.hotel_rows).forEach((hotel) => {
              // HOTEL ARR
              hotelArr.push({
                id: contract.id,
                name: contract.meta[`hotel_${hotel.id}_name`]
                  ? contract.meta[`hotel_${hotel.id}_name`].toUpperCase()
                  : "",
                quantityRooms: hotel.accommodation.length,
                value:
                  contract.meta.package_type == "Serviços"
                    ? contract.meta[`hotel_${hotel.id}_total_price`]
                    : 0,
              });
            });
          }

          // FLIGHTS
          if (JSON.parse(contract.contracted_services).includes("flight")) {
            //
          }

          // SERVICES
          if (JSON.parse(contract.contracted_services).includes("service")) {
            //
          }
        });

        newArr = _.groupBy(hotelArr, (hotel) => hotel.name);

        console.log("newArr", Object.keys(newArr), Object.values(newArr));

        let keys = Object.keys(newArr);
        let values = Object.values(newArr);

        for (var i = 0; i < keys.length; i++) {
          //   if (values[i] > 2) {
          let total = 0;
          let totalRooms = 0;
          values[i].forEach((hotel) => {
            total += parse(hotel.value);
            totalRooms += parseInt(hotel.quantityRooms);
          });

          totalQuantity += values[i].length;
          totalAccomodations += totalRooms;
          totalValue += total / totalRooms;

          chartArr.push({
            name: `${keys[i]}`,
            value: values[i].length,
            total,
            totalRooms,
            averageTicket: total / totalRooms,
            //percentage: values[i].length * 100,
          });
          //}
        }
      }

      this.chartOptions.series[0].data = chartArr;

      this.chartOptions.title.subtext =
        this.formatDate(this.filters.period.selected[0]) +
        " a " +
        this.formatDate(this.filters.period.selected[1]);

      setTimeout(() => {
        this.generateChart = true;
      }, 500);

      return {
        chartArr,
        newArr,
        totalQuantity,
        totalAccomodations,
        totalValue,
      };
    },
    // countProducts() {
    //     let arr = [];
    //     let chartArr = [];
    //     let productsArr = [];
    //     let total = 0;

    //     if (this.contractList.length > 0) {
    //         this.contractList.forEach((contract) => {
    //             // HOTEL
    //             if (
    //                 JSON.parse(contract.contracted_services).includes(
    //                     "hotel"
    //                 )
    //             ) {
    //                 JSON.parse(contract.meta.hotel_rows).forEach(
    //                     (hotel) => {
    //                         arr.push(
    //                             contract.meta[`hotel_${hotel.id}_name`]
    //                                 ? contract.meta[
    //                                       `hotel_${hotel.id}_name`
    //                                   ].toUpperCase()
    //                                 : ""
    //                         );
    //                     }
    //                 );
    //             }

    //             // FLIGHTS
    //             if (
    //                 JSON.parse(contract.contracted_services).includes(
    //                     "flight"
    //                 )
    //             ) {
    //                 //
    //             }

    //             // SERVICES
    //             if (
    //                 JSON.parse(contract.contracted_services).includes(
    //                     "service"
    //                 )
    //             ) {
    //                 //
    //             }
    //         });

    //         productsArr = arr.reduce((total, value) => {
    //             total[value] = (total[value] || 0) + 1;
    //             return total;
    //         }, {});

    //         let keys = Object.keys(productsArr);
    //         let values = Object.values(productsArr);

    //         for (var i = 0; i < keys.length; i++) {
    //             //   if (values[i] > 2) {

    //             total += values[i];
    //             chartArr.push({
    //                 name: `${keys[i]}`,
    //                 value: values[i],
    //             });
    //             //}
    //         }

    //         this.chartOptions.series[0].data = chartArr;

    //         //Relatório de Produtos

    //         // this.chartInfo.subtitle =
    //         //     this.formatDate(this.filters.period.selected[0]) +
    //         //     " a " +
    //         //     this.formatDate(this.filters.period.selected[1]);

    //         this.chartOptions.title.subtext =
    //             this.formatDate(this.filters.period.selected[0]) +
    //             " a " +
    //             this.formatDate(this.filters.period.selected[1]);
    //     } else {
    //         chartArr = [];
    //     }

    //     this.generateChart = true;

    //     return { chartArr, total, productsArr, arr };
    // },
    generatePDF() {
      this.generatePDFLoading = true;

      let element = document.getElementById("print-area");
      let options = {
        filename: `Relatório de Produtos.pdf`,
        image: { type: "jpeg", quality: 1 },
        enableLinks: false,
        margin: [8, 8, 8, 8],
        html2canvas: { scale: 2 },
        jsPDF: {
          format: "a4",
          orientation: "landscape",
        },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
      };

      html2pdf().from(element).set(options).save();

      this.generatePDFLoading = false;
    },
    changePage(current) {
      this.pagination.page = current;
      this.getContracts();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;
      this.getContracts();
    },
    contractsTableChange(pagination, filters, sorter) {
      //console.log("sorter s", sorter, pagination, filters);
      this.filters.order = sorter.order != undefined ? sorter.order : "desc";
      this.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getContracts();
    },
    getContracts(samePage) {
      this.loading = true;
      this.generateChart = false;

      let filters = "";

      let queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (!this.$root.isAdmin()) {
        filters += `&user=${this.$store.state.userData.id}`;
      } else {
        if (this.filters.users.selected.length > 0) {
          filters += `&user=${this.filters.users.selected}`;
        }
      }
      if (this.filters.companies.selected.length > 0) {
        filters += `&company=${this.filters.companies.selected}`;
      }

      if (this.filters.status.selected.length > 0) {
        filters += `&status=${this.filters.status.selected}`;
      }

      if (this.filters.paymentMethods.selected.length > 0) {
        filters += `&payment-methods=${this.filters.paymentMethods.selected}`;
      }

      if (this.filters.personTypes.selected.length > 0) {
        filters += `&person-type=${this.filters.personTypes.selected}`;
      }

      if (this.filters.productsCategory.selected.length > 0) {
        filters += `&product-categories=${this.filters.productsCategory.selected}`;
      }

      if (this.filters.embark.selected.length > 0) {
        filters += `&embark=${this.filters.embark.selected[0]}|${this.filters.embark.selected[1]}`;
      }

      if (this.filters.period.selected.length > 0) {
        filters += `&period=${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`
        );
      }

      if (this.filters.price.min || this.filters.price.max) {
        let min = this.filters.price.min;
        let max = this.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      if (this.filters.searchTerm) {
        filters += `&s=${this.filters.searchTerm}`;
      }

      if (this.filters.id) {
        filters += `&id=${this.filters.id}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      this.$http
        .get(
          `/contract/list?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.pagination.page = 1;
          }
          this.contractList = data.data;
          this.countProductsx();
          this.loading = false;
        })
        .catch(({ response }) => {
          this.contractList = [];
          this.countProductsx();
          this.loading = false;
          response;
        });
    },
    getUsers() {
      this.$http
        .get("/user/read")
        .then(({ data }) => {
          this.filters.users.list = data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.chart
  height: 700px
  width: 700px !important
  margin: 0 auto
</style>

<style>
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: #fff !important;
}

.chart-table td {
  padding: 5px 0 !important;
  font-size: 11px !important;
}

.chart-table th {
  padding: 5px 0 !important;
  font-size: 11px !important;
  background: #ffffff !important;
}

.chart-table .ant-table-footer {
  font-size: 11px !important;
  padding: 0;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
}
</style>
